import React, { useState } from "react"
import Layout from "../layout/layout"
import Meta from "../addon/meta"
import Breadcrumbs from "../addon/breadcrumbs"
import { Link } from "gatsby"

const MyReports = () => {
const [breadcrumbs, setBreadcrumbs] = useState([
{
label: "Home",
link: "/",
},
{
label: "My Reports",
},
])
const metaTags = []
return (
<>
<Layout>
    <Meta
    files={{
    js: [],
    css: ["/assets/css/blog.css", "/assets/css/accessibility.css"],
    }}
    tags={metaTags}
    />
    <main className="innerpage">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <section className="section-bg section-py">
            <div className="container">
                <div className="arrowhead">
                    <Link to="/my-account-edit" className="back_arrow">
                    <img src="/assets/images/icons/left-arrow-event.svg" alt="" />			
                    </Link>				
                    <h2 className="section-heading">John Doe</h2>
                </div>
                <div className="white_rounded_box">
                    <div className="report_head_section">
                        <div className="row">
                            <div className="col-md-9">
                                <h2>Online Medical Records</h2>
                                <p className="subtext">One place to store and manage all your medical records</p>
                                <p>Welcome to P.D. Hinduja Hospital's Online Report Portal. You can also add other medical records down below. </p>
                            </div>
                            <div className="col-md-3">
                                <p className="btn_wrap">
                                    <Link to="/my-reports-upload" className="btn btn-primary">
                                    Upload Records									
                                    </Link>					
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="event_tab_wrap">
                    <p className="my-4">Kindly click on view button from below mentioned tab to view/ print your reports.</p>
                    <div className="tab-content">
                        <div className="tab-pane active" id="myReports" role="tabpanel" aria-labelledby="myReports">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 t-data-col">
                                    <div className="card table_data_card">
                                        <div className="data_row">
                                            <span>Admit Date</span>
                                            <p>10/06/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Discharge Date</span>
                                            <p>15/06/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Days of Admit</span>
                                            <p>5</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Status</span>
                                            <p>Surgery</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Consultant</span>
                                            <p>Dr. Amol Bhanushali</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Amount</span>
                                            <p>Rs. 18,500</p>
                                        </div>
                                        <div className="data_row btn_row">
                                            <button className="btn btn-primary">Download</button>
                                            <button className="btn btn-success">View Online</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 t-data-col">
                                    <div className="card table_data_card">
                                        <div className="data_row">
                                            <span>Admit Date</span>
                                            <p>10/06/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Discharge Date</span>
                                            <p>15/06/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Days of Admit</span>
                                            <p>5</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Status</span>
                                            <p>Surgery</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Consultant</span>
                                            <p>Dr. Amol Bhanushali</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Amount</span>
                                            <p>Rs. 18,500</p>
                                        </div>
                                        <div className="data_row btn_row">
                                            <button className="btn btn-primary">Download</button>
                                            <button className="btn btn-success">View Online</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 t-data-col">
                                    <div className="card table_data_card">
                                        <div className="data_row">
                                            <span>Admit Date</span>
                                            <p>10/06/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Discharge Date</span>
                                            <p>15/06/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Days of Admit</span>
                                            <p>5</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Status</span>
                                            <p>Surgery</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Consultant</span>
                                            <p>Dr. Amol Bhanushali</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Amount</span>
                                            <p>Rs. 18,500</p>
                                        </div>
                                        <div className="data_row btn_row">
                                            <button className="btn btn-primary">Download</button>
                                            <button className="btn btn-success">View Online</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn_wrap text-center">
                                <button className="btn btn-primary">LOAD MORE</button>
                            </div>
                        </div>
                        <div className="tab-pane" id="pastBills" role="tabpanel" aria-labelledby="pastBills">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 t-data-col">
                                    <div className="card table_data_card">
                                        <div className="data_row">
                                            <span>Admit Date</span>
                                            <p>10/04/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Discharge Date</span>
                                            <p>15/04/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Days of Admit</span>
                                            <p>5</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Status</span>
                                            <p>Surgery</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Consultant</span>
                                            <p>Dr. Amol Bhanushali</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Amount</span>
                                            <p>Rs. 18,500</p>
                                        </div>
                                        <div className="data_row btn_row">
                                            <button className="btn btn-primary">Download</button>
                                            <button className="btn btn-success">View Online</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 t-data-col">
                                    <div className="card table_data_card">
                                        <div className="data_row">
                                            <span>Admit Date</span>
                                            <p>10/04/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Discharge Date</span>
                                            <p>15/04/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Days of Admit</span>
                                            <p>5</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Status</span>
                                            <p>Surgery</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Consultant</span>
                                            <p>Dr. Amol Bhanushali</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Amount</span>
                                            <p>Rs. 18,500</p>
                                        </div>
                                        <div className="data_row btn_row">
                                            <button className="btn btn-primary">Download</button>
                                            <button className="btn btn-success">View Online</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 t-data-col">
                                    <div className="card table_data_card">
                                        <div className="data_row">
                                            <span>Admit Date</span>
                                            <p>10/04/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Discharge Date</span>
                                            <p>15/04/2021 </p>
                                        </div>
                                        <div className="data_row">
                                            <span>Days of Admit</span>
                                            <p>5</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Status</span>
                                            <p>Surgery</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Consultant</span>
                                            <p>Dr. Amol Bhanushali</p>
                                        </div>
                                        <div className="data_row">
                                            <span>Amount</span>
                                            <p>Rs. 18,500</p>
                                        </div>
                                        <div className="data_row btn_row">
                                            <button className="btn btn-primary">Download</button>
                                            <button className="btn btn-success">View Online</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn_wrap text-center">
                                <button className="btn btn-primary">LOAD MORE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</Layout>
</>
)
}
export default MyReports