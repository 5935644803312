import * as React from "react"
// import { graphql, useStaticQuery, Link, navigate } from "gatsby"

const Breadcrumbs = (props) => {

//     const {
//         breadcrumbs,
//         sectionClasses
//     } = props

//     const secClasses = sectionClasses ? sectionClasses : ''
//     const createLI = (data, index) => {
//         if (data.hasOwnProperty('link')) {
//             return  (
//                 <li key={index}>
//                     <Link to={data.link}>{data.label}</Link>
//                 </li>
//             )
//         } else return <li key={index}><span>{data.label}</span></li>
//     }

//     const data = useStaticQuery(graphql`
//     query breadcrumbQuery {
//         allNodeTemplatePages {
//         edges {
//         node {
//         id
//         title
//         path {
//         alias
//         }
//         relationships {
//         field_breadcrumb {
//         field_title
//         field_breadcrumb_link {
//         title
//         uri
//         }
//         }
//         }
//         }
//         }
//         }
//         }
// `)

    return (
        <></>
        // <section className={`breadcrumbs ${secClasses}`}>
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-lg-12">
        //                 <ul>
        //                     {
        //                         breadcrumbs.map((el, index) => {
        //                             return createLI(el, index)
        //                         })
        //                     }
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}

export default Breadcrumbs