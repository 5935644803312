import axios from "axios"

import config from "../service/config"
import { getCookie } from "../service/helper";

let headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
}

let myAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers,
    // auth: {
    //     username: config.GATSBY_DRUPAL_USERNAME,
    //     password: config.GATSBY_DRUPAL_PASSWORD
    // }
})

myAxios.interceptors.request.use(function (config) {
    return config;
}, function(error) {
    return Promise.reject(error);
});

myAxios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    return Promise.reject(error)
});

export function initHeaders() {
    myAxios.defaults.headers.common['Authorization'] = "Bearer " + getCookie('login_id')
}

const api = {
    getEvents(params) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.EVENTS.EVENTS, {
                params: {...params}
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getDataFromGraphQury(queryData, params) {
        return new Promise((resolve, reject) => {
            myAxios.post('/jsonapi', {
                data: {
                  query: queryData
                },
                variables: {
                    offset: params.offset,
                    limit: params.limit,
                    currentDate: params.currentDate
                }
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch(e => {
                reject(e)
            })
        })
    },

    getEventsData(params) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.EVENTS.EVENTS_DATA, {
                params: {...params}
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getEventsDetails(id) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.EVENTS.EVENTS_DATA+'/'+id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getUpcomingEvents(type) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.EVENTS.EVENTS_DATA+'?when=future&type='+type)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    eventRegistration(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.EVENTS.EVENTS_REGISTRATION, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getArchivedEventsData(query) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.EVENTS.EVENTS_ARCHIVED_DATA+query)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    eventChecksum(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.EVENTS.EVENT_CHECKSUM, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    registerForEvent(eventId)
    {
        return new Promise((resolve, reject) => {
            myAxios.post(`${config.REGISTER_EVENT}/${eventId}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    registeredEvents() {
        return new Promise((resolve, reject) => {
            myAxios.get(`${config.REGISTERED_EVENTS}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    getPressReleases(query) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.PRESS_RELEASE.PRESS_RELEASE+query)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    getPressReleaseKeyNews(query){
        return new Promise((resolve,reject) => {
            myAxios.get(config.PRESS_RELEASE.KEY_NEWS+query)
            .then((response) => {
                resolve(response.data)
            }).catch(e => {
                reject(e)
            })
        })
    },
    getPressReleaseKeyCoverage(query) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.PRESS_RELEASE.KEY_COVERAGE+query)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
   
    getPressReleasesMediaContent() {
        return new Promise((resolve, reject) => {
            myAxios.get(config.PRESS_RELEASE.MEDIA_CONTENT)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    getPressReleasesVideo()     {
        return new Promise((resolve, reject) => {
            myAxios.get(config.PRESS_RELEASE.VIDEO)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getEventVoucherDetails(id) {
        return new Promise((resolve, reject) => {
            myAxios.get(config.EVENTS.VOUCHER_DETAILS+'/'+id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
}

export default api
