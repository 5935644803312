import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/userPages/privateRoute"
import PublicRoute from "../components/userPages/publicRoute"
import Login from "../components/userPages/login"
import Default from "../components/userPages/default"
import Register from "../components/userPages/register"
import MyEvents from "../components/userPages/my-events"
import Dashboard from "../components/userPages/dashboard"
import MyFamilyHHNumbers from "../components/userPages/my-family-hh-numbers"
import MyReports from "../components/userPages/my-reports"
import MyBills from "../components/userPages/my-bills"
import MyAccount from "../components/userPages/my-account"
import MyAppointments from "../components/userPages/my-appointments"

const User = () => {
return (
    <Router basepath="/user">
        <PrivateRoute path="/myevents" component={MyEvents} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/my-family-hh-numbers" component={MyFamilyHHNumbers} />
        <PrivateRoute path="/my-appointments" component={MyAppointments} />
        <PrivateRoute path="/my-reports" component={MyReports} />
        <PrivateRoute path="/my-bills" component={MyBills} />
        <PrivateRoute path="/my-account" component={MyAccount} />
        <PublicRoute path="/register" component={Register} />
        <PublicRoute path="/login" component={Login} />
        <Default path="/" />
    </Router>
)
}
export default User
