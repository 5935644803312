import axios from "axios"
import config from "../service/config"
import {Buffer} from 'buffer';

let headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
}

let myAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers,
    auth: {
        username: config.GATSBY_DRUPAL_USERNAME,
        password: config.GATSBY_DRUPAL_PASSWORD
    }
})

const api = {
    submitFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const fileByteArray = [];
            reader.onload = (e) => {
                if (e.target.readyState == FileReader.DONE) {
                    const arrayBuffer = e.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);
                    }
                    axios.post(config.API_URL + config.CAREER_FILE_UPLOAD, Buffer.from(fileByteArray), {
                        headers: { 
                            "Content-Type": "application/octet-stream", 
                            "Content-Disposition": `file; filename="${file.name}"; size:${file.size}`
                        }
                    })
                    .then(r => {
                        resolve(r.data)
                    })
                    .catch(err => {
                        reject(err)
                    }) 
                }
            }
            reader.readAsArrayBuffer(file);
        })
    },
    submitFormData(params) {
        return myAxios.post(config.WEBFORM_SUBMIT, params)
    },

    submitTestimonialForm(payload) {
        return myAxios.post(config.TESTIMONIAL_FORM, payload)
    },

    submitImage(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const fileByteArray = [];
            reader.onload = (e) => {
                if (e.target.readyState == FileReader.DONE) {
                    const arrayBuffer = e.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);
                    }
                    axios.post(config.API_URL + config.IMAGE_UPLOAD, Buffer.from(fileByteArray), {
                        headers: { 
                            "Content-Type": "application/octet-stream", 
                            "Content-Disposition": `file; filename="${file.name}"; size:${file.size}`
                        }
                    })
                    .then(r => {
                        resolve(r.data)
                    })
                    .catch(err => {
                        reject(err)
                    }) 
                }
            }
            reader.readAsArrayBuffer(file);
        })
    },

}

export default api

