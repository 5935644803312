import React from "react"

import { navigate } from "gatsby"

import { isLoggedIn } from "../../service/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    if (!isLoggedIn() && location.pathname !== `/user/login`) {
      navigate("/user/login")
      return null
    }
    return <Component {...rest} />
  }
export default PrivateRoute

// const PrivateRoute = ({ component: Component, location, ...rest }) => {
//     if (isLoggedIn() && location.pathname === `/user/login`) {
//         navigate("/")
//         return null
//     }
//     if (!isLoggedIn() && location.pathname === `/user/myevents`) {
//         navigate("/user/login")
//         return null
//     }

//     if (!isLoggedIn() && location.pathname === `/user/dashboard`) {
//         navigate("/user/login")
//         return null
//     }

//     if (!isLoggedIn() && location.pathname === `/user/my-family-hh-numbers`) {
//         navigate("/user/login")
//         return null
//     }

//     if (!isLoggedIn() && location.pathname === `/user/my-appointments`) {
//         navigate("/user/login")
//         return null
//     }


//     if (!isLoggedIn() && location.pathname === `/user/my-reports`) {
//         navigate("/user/login")
//         return null
//     }

//     if (!isLoggedIn() && location.pathname === `/user/my-bills`) {
//         navigate("/user/login")
//         return null
//     }

//     if (!isLoggedIn() && location.pathname === `/user/my-account`) {
//         navigate("/user/login")
//         return null
//     }

//     if (!isLoggedIn() && location.pathname === `/user/help-centre`) {
//         navigate("/user/login")
//         return null
//     }

//     return <Component {...rest} />
// }

// export default PrivateRoute
