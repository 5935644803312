import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"

import Layout from "../layout/layout"
import Meta from "../addon/meta"
import Breadcrumbs from "../addon/breadcrumbs"

import { checkIfValidJSON, eraseCookie, getCookie, getNameInitials } from "../../service/helper"

const Dashboard = () => {
    const [breadcrumbs] = useState([
        { label: "Home", link: "/",},
        { label: "Dashboard", },
    ])
    const isUserLoggedIn = getCookie("login_id")
    let [userInfo, setUserInfo] = useState(getCookie("user"))
    const metaTags = []

    const logoutUser = () => {
        if (isUserLoggedIn) {
          eraseCookie("login_id")
          eraseCookie("user")
          eraseCookie("hh")
          navigate('/')
        }
    }

    useEffect(() => {
        if (userInfo && checkIfValidJSON(userInfo)) {
            setUserInfo(JSON.parse(userInfo))
        }
    }, [])
 
    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: ["/assets/css/login.css", "/assets/css/accessibility.css"],
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <section className="login_section section-bg section-py">
                    <div className="container">
                        <div className="white_rounded_box">
                            <div className="my_account_edit_wrap">
                                <div className="row no-gutters">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="edit_with_image">
                                            <div className="edit_with_image_inner">
                                                <div className="img_section"> 
                                                    {userInfo?.profileImage 
                                                    ? <img src={userInfo?.profileImage} alt="" /> 
                                                    : <span className="default-image">{getNameInitials(userInfo?.user_name)}</span>}
                                                    {/* <img src="/assets/images/my-account-edit/dr-pravin-gore.png" alt="" />  */}
                                                </div>
                                                <aside>
                                                    <h2>{userInfo?.user_name || ''} {userInfo?.hh_number ? `(${userInfo?.hh_number})` : ''}  </h2>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Link to="/user/my-account" className="link_edit">View and edit profile</Link>
                                                            {/* <div className="completion">25% Completed</div> */}
                                                        </div>
                                                    </div>
                                                </aside>
                                            </div>
                                            <div className="btn_wrap"> 
                                                <a href="javascript:;" className="btn-primary-solid" onClick={logoutUser}>LOGOUT</a> 
                                            </div>
                                            <div className="card_list_container">
                                                <div className="row">
                                                    <div className="col-md-3 col-6 list-col">
                                                        <Link to="/user/my-family-hh-numbers" className="edit_card">
                                                        <figure> <img src="/assets/images/icons/icon_family_hh_number.svg" alt="" /> </figure>
                                                        <span className="link_wrap">
                                                        View Family HH Numbers
                                                        </span>										
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-3 col-6 list-col">
                                                        <Link to="/user/my-appointments" className="edit_card">
                                                        <figure> <img src="/assets/images/icons/icon_my_appointments.svg" alt="" /> </figure>
                                                        <span className="link_wrap">
                                                        My <br /> Appointments
                                                        </span>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-3 col-6 list-col">
                                                        <Link to="/medical-report/details" className="edit_card">
                                                            <figure> <img src="/assets/images/icons/icon_my_reports.svg" alt="" /> </figure>
                                                            <span className="link_wrap">
                                                            My Medical Reports
                                                            </span>
                                                        </Link>										
                                                    </div>
                                                    <div className="col-md-3 col-6 list-col">
                                                        <Link to="/user/my-bills" className="edit_card">
                                                        <figure> <img src="/assets/images/icons/icon_my_bills.svg" alt="" /> </figure>
                                                        <span className="link_wrap">
                                                        My bills
                                                        </span>										
                                                        </Link>										
                                                    </div>
                                                    {/* <div className="col-md-3 col-6 list-col">
                                                        <Link to="/user/myevents" className="edit_card">
                                                        <figure> <img src="/assets/images/icons/icon_events.svg" alt="" /> </figure>
                                                        <span className="link_wrap">
                                                        Events	
                                                        </span>										
                                                        </Link>
                                                    </div> */}
                                                    <div className="col-md-3 col-6 list-col">
                                                        <Link to="/user/my-account" className="edit_card">
                                                        <figure> <img src="/assets/images/icons/icon_settings.svg" alt="" /> </figure>
                                                        <span className="link_wrap">
                                                        Settings
                                                        </span> </Link>
                                                    </div>
                                                    <div className="col-md-3 col-6 list-col">
                                                        <Link to="/help-centre" className="edit_card">
                                                        <figure> <img src="/assets/images/icons/icon_help_center.svg" alt="" /> </figure>
                                                        <span className="link_wrap">
                                                        Help Center	
                                                        </span>										
                                                        </Link>										
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default Dashboard