import React from "react"

import { navigate } from "gatsby"

import { isLoggedIn } from "../../service/auth"

const PublicRoute = ({ component: Component, location, ...rest }) => {
    if (isLoggedIn() && (location.pathname === `/user/login`)) {
      navigate("/user/dashboard")
      return null
    }
    return <Component {...rest} />
  }
export default PublicRoute