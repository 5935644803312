import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import SimpleReactValidator from "simple-react-validator"
import toast from "react-hot-toast"

import Layout from "../layout/layout"
import Meta from "../addon/meta"
import Breadcrumbs from "../addon/breadcrumbs"
import Loader from "../addon/Loader"
import LinkButton from "../addon/button/linkButton"
import APIButton from "../addon/button/apiButton"

import useScript from "../../service/useScript"
import { acceptOnlyNumbers, checkIfValidJSON, getCookie, getNameInitials } from "../../service/helper"
import { handleHHLogin } from '../../service/auth'
import USER_API from '../../api/user'
import API from '../../api/index'

const MyFamilyHHNumbers = () => {

    const [breadcrumbs ] = useState([
        { label: "Home", link: "/",},
        { label: "Dashboard", link: "/user/dashboard",},
        { label: "My Family HH Numbers", },
    ])
    const ogForm = {
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        relationship: "",
        relationshipDesc: '',
        mobile: ''
    }
    const [familyMemberInfo, setFamilyMemberInfo] = useState(ogForm)
    const [otp, setOTP] = useState("")
    
    const [step2, setStep2] = useState(false) // show hh, mobile, otp 
    const [step3, setStep3] = useState(false) // show complete form 
    const [showForm, setShowForm] = useState("")
    const [loading, setLoading] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [otpLoading, setOTPLoading] = useState(false)
    const [members, setMembers] = useState([])
    const [userInfo, setUserInfo] = useState(getCookie("user"))
    const [hhNumber, setHHNumber] = useState('')
    const [userFound, setUserFound] = useState(null)
    const [relationData, setRelationData] = useState([])
    const [, forceUpdateForRegister] = useState();
    const [selectUser, setSelectUser] = useState('')
    const [userDetails, setUserDetails] = useState(null)
    const [userDeleted, setUserDeleted] = useState(false)
        
    const metaTags = []
    // useScript("/assets/js/my-family-hh-numbers.js")
    useScript("/assets/js/custom_select.js"); 
    useScript("/assets/js/login-register.js");

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => 
            <p className="field_error" > {message} </p>
        }
    )))

    const resetForm = () => {
        setStep2(false)
        setStep3(false)
        setLoading(false)
        setShowForm(false)
        setFamilyMemberInfo(ogForm)
        setHHNumber('')
        setOTP('')
        setUserFound(null)
    }

    const resetData = () => {
        setSelectUser('')
        setUserDetails(null)
        setUserDeleted(false)
    }

    const showAddForm = () => {
        setShowForm(true)
    }

    const goBack = () => {
        if(!step2 && !step3) {
            setShowForm(false)
            setHHNumber('')
            forceUpdateForRegister(5)
            formValidator.current.hideMessages()
        } 
        if(step2 && !step3) {
            setStep2(false)
            setOTP('')
            setUserFound(null)
            setFamilyMemberInfo(ogForm)
            forceUpdateForRegister(6)
            formValidator.current.hideMessages()
        }
        if(step2 && step3) {
            setStep3(false)
            setOTP('')
            forceUpdateForRegister(7)
            formValidator.current.hideMessages()
        }
    }

    const onFamilyMemberInfoChange = (e) => {
        const { name, value } = e?.target
        if(name === 'relationship') {
            let found = relationData?.find(el => el?.code === value)
            setFamilyMemberInfo({
                ...familyMemberInfo,
                [name]: value,
                relationshipDesc: found?.description
            })
        } else {
            setFamilyMemberInfo({
                ...familyMemberInfo,
                [name]: value
            })
        }
    }

    // const editMember = (number) => {
    //     setFamilyMemberInfo(members.find(it => it.hhNumber === number) )	
    // }

    // const edit = () => {
    //     toast.success("Member editted Succesfully")
    // }

    const onResendOTP = (mobile_no) => {
        setOTPLoading(true)
        USER_API.sendOTP({ mobileNo : mobile_no ? mobile_no : familyMemberInfo?.mobile, "otpFor": "family_members" })
        .then(response => {
            toast.success("OTP sucessfully sent on mobile no.")
            setOTPLoading(false)
        })
        .catch(err => {
            console.log(err)
            toast.error("Something went wrong. Please try again later")
            setOTPLoading(false)
        })
    }

    const handleVerifyHHNumber = (e) => {
        e.preventDefault();
        const validhh =  members.filter(x => x.hh_number === hhNumber)

        if(validhh.length > 0 || userInfo?.hh_number === hhNumber){
            return toast.error("HH no. already exist. Please use another HH no.")
        }
        const result = formValidator.current.allValid()
        if(!result) {
            formValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if(result && hhNumber?.length) {
            validateUserHHNumber()
            formValidator.current.hideMessages()
        }
    }

    const handleOTPVerify = () => {
        const result = formValidator.current.allValid()
        if(!result) {
            formValidator.current.showMessages()
            forceUpdateForRegister(2)
        }
        if(result && otp?.length) {
            setLoading(true)
            formValidator.current.hideMessages()
            USER_API.verifyOTP(otp)
            .then(res => {
                if(res === true) {
                    setStep3(true)
                } else {
                    toast.error("Invalid OTP")
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                toast.error("Something went wrong. Please try again later")
            })
        }
        
    }

    const handleSubmit = () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdateForRegister(3)
        } else {	
            let payload = {
                "title": familyMemberInfo?.title,
                "first_name": familyMemberInfo?.firstName,
                "middle_name": familyMemberInfo?.middleName,
                "last_name": familyMemberInfo?.lastName ,
                "relation": familyMemberInfo?.relationship,
                "gender": familyMemberInfo?.gender,
                "user_hh_number": userInfo?.hh_number,
                "hh_number": hhNumber
            }
            addNewFamilyMember(payload)	
        }
    }

    const validateUserHHNumber = () => {
        setLoading(true)
        USER_API.validateHHNumber(hhNumber)
        .then(res => {
            if(res) {
                getDetailsHHNumber()
            } else {
                toast.error("Invalid HH Number")
                setLoading(false)
            }
        })
        .catch(err => {
            console.log(err)
            toast.error("Invalid HH Number")
            setLoading(false)
        })
    }

    const getDetailsHHNumber = async () => {
        try {
            const result = await handleHHLogin(hhNumber)
            if(result?.mobileno) {
                setStep2(true)
                setUserFound(result)
                setFamilyMemberInfo({
                    ...familyMemberInfo,
                    mobile: result?.mobileno,
                    gender: result?.gender,
                    firstName: result?.firstname,
                    lastName: result?.lastname,
                    middleName: result?.midname
                })
                onResendOTP(result?.mobileno)
            } else {
                toast.error("HH Number not associated with mobile number")
            }
            setLoading(false)
        } catch (error) {
            toast.error("Something went wrong")
            console.log(error)
            setLoading(false)
        }
    }

    const addNewFamilyMember = (payload) => {
        setLoading(true)
        API.addFamilyMembers(payload)
        .then(res => {
            toast.success("Member added successfully")
            resetForm()
            if(Number(userInfo?.hh_number)) {
                getFamilyMembersData(userInfo?.hh_number)
            }
            setLoading(false)
        })
        .catch(err => {
            setLoading(false)
            console.log(err)
            toast.error("Something went wrong")
        })
    }

    const getRelationsData = () => {
        USER_API.getRelations()
        .then(res => {
            if(res?.Data) {
                setRelationData(res?.Data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const deactivateUser = () => {
        if(selectUser) {
            let payload = {
                "PatPIN": selectUser?.pinNo,
                "PatMobNo": " ",
                "PatEmailId": selectUser?.email,
                "Tele_resident": selectUser?.patTelRes,
                "Tele_work": selectUser?.patTelWork,
            }
            USER_API.updatePatient(payload)
            .then(res => {
                setUserDeleted(true)
                getFamilyMembersData(userInfo?.mobileno)
            }) 
            .catch(err => {
                console.log(err)
            })
        }
    }

    const getUserDetailsfromHH = (hh_no) => {
        USER_API.getUserFromHH(hh_no)
        .then(res => {
            if(res?.Data?.[0]) {
                setUserDetails(res.Data[0])
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const setUserForDelete = (user) => {
        setSelectUser(user)
        // if(user?.hh_number) getUserDetailsfromHH(user?.hh_number)
    }
 
    const getFamilyMembersData = (mobile) => {
        setDataLoading(true)
        USER_API.getUserFromMobileNumber(mobile)
        .then(response => {
            if(response?.Data){
                let arr = response.Data.filter(el => el?.regType === 'HH')
                setMembers(arr)
            }
            setDataLoading(false)
        })
        .catch(err => {
            console.log(err)
            setDataLoading(false)
            toast.error("Something went wrong")
        })
        // API.getFamilyMembers(hh_no)
        // .then(res => {
        //     if(res?.[hh_no]?.length) {
        //         setMembers(res[hh_no])
        //     }
        //     setDataLoading(false)
        // })
        // .catch(err => {
        //     console.log(err)
        //     setDataLoading(false)
        //     toast.error("Something went wrong")
        // })
    }

    useEffect(() => {
        if (userInfo && checkIfValidJSON(userInfo)) {
            setUserInfo(JSON.parse(userInfo))
        }
        getRelationsData()
    }, [])

    useEffect(() => {
        if(Number(userInfo?.mobileno)) {
            getFamilyMembersData(userInfo?.mobileno)
        }
    },[userInfo])

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
        forceUpdateForRegister(4)
    }, [familyMemberInfo, relationData, step2, step3, loading])

    return (
        <Layout>
            <Meta
                files={{
                    js: [""],
                    css: ["/assets/css/login.css", "/assets/css/confirmpay.css", "/assets/css/accessibility.css"],
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <section className="section_bg pt-first-section">
                    <div className="container">
                        <div className="arrowhead">
                            <Link to="/user/dashboard" className="back_arrow">
                            <img src="/assets/images/icons/left-arrow-event.svg" alt="" />
                            </Link>
                            <h2 className="section-heading">My Family HH Numbers</h2>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-lg-12 col-md-12">
                                <div className="white_rounded_box confirm_pay">
                                    <div className="common_section head_section">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4">
                                                <div className="row align-items-center">
                                                    <div className="col-md-3 col-3">
                                                        <figure> 
                                                            {userInfo?.profileImage
                                                            ? <img src={userInfo?.profileImage} alt="" /> 
                                                            : <span className="default-image">{getNameInitials(userInfo?.user_name)}</span>}
                                                            {/* <img src="/assets/images/doctor_70x70.jpg" alt="" />  */}
                                                        </figure>
                                                    </div>
                                                    <div className="col-md-9 col-9">
                                                        <div className="doctor_name"> <span>User Name</span>
                                                            <br /> {userInfo?.user_name || ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="common_section appoinment_for">
                                        <h3>My Family HH Numbers</h3>
                                        <div className="user-carousel row d-flex-wrap">
                                            {members?.length && !dataLoading
                                            ? members.map((item, index) => {
                                                return (
                                                    <div className="card-slide mx-2 mb-3 width-100px" key={index}>
                                                        <div className="hh_card">
                                                            <div class="btn_group">
                                                                <button onClick={() => setUserForDelete(item)} class="btn btn-delete" data-target="#delete_confirm_modal" data-toggle="modal">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 38 48">
                                                                        <g transform="translate(-7 -2)">
                                                                            <path class="a" d="M36.205,49H15.8a2,2,0,0,1-1.988-1.785L10,12H42L38.193,47.215A2,2,0,0,1,36.205,49Z" />
                                                                            <path class="a" d="M44,12H8V11a4,4,0,0,1,4-4H40a4,4,0,0,1,4,4Z" />
                                                                            <path class="a" d="M32,7H20V5a2,2,0,0,1,2-2h8a2,2,0,0,1,2,2Z" />
                                                                            <line class="b" y2="25" transform="translate(20 18)" />
                                                                            <line class="b" y2="25" transform="translate(26 18)" />
                                                                            <line class="b" y2="25" transform="translate(32 18)" />
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            <figure> 
                                                                <img src={`/assets/images/icons/appointment_user_${item?.gender === 'M' ? 'male' : 'female'}.svg`} alt="" className="normal" /> 
                                                                <img src={`/assets/images/icons/appointment_user_${item?.gender === 'M' ? 'male' : 'female'}_active.svg`} alt="" className="active" /> 
                                                            </figure>
                                                            <p> {item?.fullname} <br /> <span>{item?.pinNo}</span> </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null}
                                            {/* <div className="card-slide mx-2 mb-3 width-100px" onClick={showAddForm}>
                                                <div className="hh_card add_user_btn">
                                                    <figure> <img src="/assets/images/icons/add_user.svg" alt="" className="normal" /> <img src="/assets/images/icons/add_user_active.svg" alt="" className="active" /> </figure>
                                                    <p> + Add User </p>
                                                </div>
                                            </div> */}
                                        </div>
                                        {dataLoading && <Loader loadingMessage="Loading..." />}
                                        {!members?.length && !dataLoading && <p className="mb-0">No Members found.</p>}
                                    </div>
                                </div>
                                <div className={`white_rounded_box my-5 add_hh_no_form ${showForm ? '' : 'd-none'}`}>
                                <div className="form_wrap">
                                    <h2 className="section-heading">Add HH Number</h2>
                                    <div className="form_block pl-0">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="row otp_row">
                                                    <div className="col-lg-4 col-md-12">
                                                        <div className={`form-group -animated ${step2 || step3 ? '-active select-disabled' : ''}`}>
                                                            <label>HH Number <span className="mandatory">*</span></label>
                                                            <input className="form-control" type="text" value={hhNumber} onChange={(e) => setHHNumber(acceptOnlyNumbers(e?.target?.value))} name="hhNumber" />
                                                            {formValidator.current.message('hhNumber', hhNumber, 'required|max:12')}
                                                        </div>
                                                        {/* <LinkButton title={`Verify HH Number`} resend onBtnClick={handleVerifyHHNumber} loading={verifyLoading} /> */}
                                                    </div>
                                                    {step2 && !step3
                                                    ? <>
                                                        <div className="col-lg-4 col-md-12">
                                                            <div className="form-group -animated -focus -active select-disabled">
                                                                <label>Mobile No<span className="mandatory">*</span></label>
                                                                <input className="form-control" type="text" disabled value={familyMemberInfo?.mobile.replace(/^.{7}/g, '*******')} onChange={(e) => {}} name="mobile" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-12">
                                                            <div className="row otp_row">
                                                                <div className="col-lg-8 col-md-12">
                                                                    <div className="form-group -animated">
                                                                        <label>Enter OTP <img src="/assets/images/icons/icon_info.svg" alt="" /> </label>
                                                                        <input onChange={(e) => setOTP(acceptOnlyNumbers(e.target.value))} className="form-control" type="text" value={otp} />
                                                                        {formValidator.current.message('otp', otp, 'required')}
                                                                        {/* <i className="icon success"></i>  */}
                                                                        <span className="err_msg">Please enter OTP shared on your mobile number.</span>
                                                                        {/* <span className="field_note">If OTP not recieved send via SMS or WhatsApp.</span>  */}
                                                                    </div>
                                                                    <LinkButton title={`Resend OTP`} resend onBtnClick={() => onResendOTP()} loading={otpLoading} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null}
                                                </div>
                                            </div>
                                            {/* {
                                            showOTPField === true &&
                                            <div className="col-lg-6 col-md-12">
                                                <div className="row otp_row">
                                                    <div className="col-lg-8 col-md-12">
                                                        <div className="form-group -animated">
                                                            <label>Enter OTP <img src="/assets/images/icons/icon_info.svg" alt="" /> </label>
                                                            <input
                                                            // onChange={(e) => setOTP(event.target.value)}
                                                            className="form-control"
                                                            type="text"
                                                            value={otp}
                                                            />
                                                            <i className="icon success"></i> <span className="err_msg">Please enter OTP shared on your mobile number.</span>
                                                            <span className="field_note">If OTP not recieved aend via SMS or WhatsApp.</span> 
                                                        </div>
                                                        <div className="resend_otp">
                                                            <a href="javascript:void(0)" onClick={onResendOTP}> Resend OTP <img src="/assets/images/icons/icon_rotate.svg" alt="" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            } */}
                                        </div>
                                        {step3 
                                        ?   <div className={`row`}>
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="form-group select-dd">
                                                            <label>Title <span className="mandatory">*</span></label>
                                                            <select value={familyMemberInfo.title} onChange={onFamilyMemberInfoChange} placeholder={familyMemberInfo.title ? familyMemberInfo.title : "Select Title"} name="title" id="title" className="form-control custom-select" >
                                                                {/* <option value="" disabled>Select Title</option> */}
                                                                <option value="Mr.">Mr.</option>
                                                                <option value="Miss.">Miss.</option>
                                                                <option value="Dr.">Dr.</option>
                                                            </select>
                                                            {formValidator.current.message('title', familyMemberInfo.title, 'required')}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className={`form-group -animated ${userFound?.firstname ? '-active select-disabled' : ''}`}>
                                                            <label>First Name <span className="mandatory">*</span></label>
                                                            <input onChange={onFamilyMemberInfoChange} value={familyMemberInfo.firstName} id="firstName" name="firstName" className="form-control" type="text" />
                                                            {formValidator.current.message('firstName', familyMemberInfo.firstName, 'required')}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className={`form-group -animated ${userFound?.midname ? '-active select-disabled' : ''}`}>
                                                            <label>Middle Name </label>
                                                            <input onChange={onFamilyMemberInfoChange}  value={familyMemberInfo.middleName} name="middleName" className="form-control" type="text" />
                                                            {/* {formValidator.current.message('middleName', familyMemberInfo.middleName, 'required')} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className={`form-group -animated ${userFound?.lastname ? '-active select-disabled' : ''}`}>
                                                            <label>Last Name </label>
                                                            <input onChange={onFamilyMemberInfoChange} value={familyMemberInfo.lastName} name="lastName" className="form-control" type="text" />
                                                            {formValidator.current.message('lastName', familyMemberInfo.lastName, 'required')} 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="form-group select-dd">
                                                    <label>Relationship <span className="mandatory">*</span></label>
                                                    <select onChange={onFamilyMemberInfoChange} value={familyMemberInfo.relationship} placeholder={familyMemberInfo?.relationshipDesc ? familyMemberInfo.relationshipDesc : "Select Relationship"} name="relationship" id="relationship" className="form-control custom-select">
                                                        {/* <option value="" selected disabled>Select Relationship</option> */}
                                                        {relationData.length 
                                                        ? relationData.map(relation => <option key={relation?.code} value={relation?.code}>{relation?.description}</option>)
                                                        : null}
                                                    </select>
                                                    {formValidator.current.message('relationship', familyMemberInfo.relationship, 'required')}
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                        
                                        <div className="row mt-4">
                                            <div className="col-12">
                                                {step3 
                                                    ? <>
                                                        <APIButton onBtnClick={(e) => goBack(e)} title={`Back`} disabled={loading} className={`btn btn-primary mr-2 mb-3`} />
                                                        <APIButton onBtnClick={(e) => handleSubmit(e)} title={`EDIT & SAVE`} loading={loading} disabled={loading} className={`btn btn-primary`} />
                                                    </>
                                                    :  step2 
                                                        ? <>
                                                            <APIButton onBtnClick={(e) => goBack(e)} title={`Back`} disabled={loading} className={`btn btn-primary mr-2 mb-3`} />
                                                            <APIButton onBtnClick={(e) => handleOTPVerify(e)} title={`Verify OTP`} loading={loading} disabled={loading} className={`btn btn-primary`} />
                                                        </>
                                                        :  <>
                                                            <APIButton onBtnClick={(e) => goBack(e)} title={`Back`} disabled={loading} className={`btn btn-primary mr-2 mb-3`} />
                                                            <APIButton onBtnClick={(e) => handleVerifyHHNumber(e)} title={`Verify HH Number`} loading={loading} disabled={loading} className={`btn btn-primary`} />
                                                        </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Edit User */}
                            <div className="white_rounded_box my-5 edit_hh_no_form" style={{ display: "none" }}>
                    </div>
                    </div>
                    </div>
                    </div>
                </section>
            </main>
            {/* delete modal */}
            <div id="delete_confirm_modal" className="modal fade confirmation_modal common_modal">
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <button onClick={resetData} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-header">
                            <h2>{userDeleted ? 'Map new mobile' : 'Are You Sure?'}</h2>
                        </div>
                        <div className="modal-body">
                            <p>{userDeleted 
                                ? <>{`Kindly map a new mobile to ${selectUser?.pinNo} HH no.`}  <a data-dismiss="modal" href='javascript:void(0)' onClick={() => navigate('/activate-account')}>Click here</a></> 
                                : ` HH no “${selectUser?.pinNo}” will be disassociated from your profile and you will not be able to book appointment for this HH ${selectUser?.pinNo} from your account any longer.` }
                            </p>
                        </div>
                        {!userDeleted && 
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={deactivateUser}>Delete</button>
                        </div>}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default MyFamilyHHNumbers