import React, { useEffect, useState } from "react"

import Modal from "react-bootstrap/Modal"

import { getYouTubeId } from "../../../service/helper"

import Loader from "../Loader"

const VideoModal = (props) => {

    const {
        videoURL,
        showModal,
        closeModal
    } = props

    const [loader, setLoader] = useState(false)
    useEffect(() => {
        if (showModal === true) {
            setLoader(true)
            document.getElementById("youtubeVideoPlayer").onload = () => {
                setLoader(false)
            }
        }
    }, [showModal])
    return (
        <Modal
            show={showModal}
            animation={false}
            centered
            size="lg"
            dialogClassName="modal_video"
        >
            <Modal.Body>
                <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">X</span>
                </button>
                {
                    videoURL &&
                    <>
                        {
                            loader === true &&
                            <Loader
                                loadingMessage="Loading..."
                            />
                        }
                        <iframe
                            id="youtubeVideoPlayer"
                            width="100%"
                            height="315"
                            src={`//www.youtube.com/embed/${getYouTubeId(videoURL)}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}

export default VideoModal
