import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import toast from "react-hot-toast"
import { navigate } from 'gatsby-link'

import Layout from "../layout/layout"
import Meta from "../addon/meta"
import Breadcrumbs from "../addon/breadcrumbs"
import LoadMoreButton from "../addon/loadMoreButton"

import DOCTOR_API from "../../api/doctors"
import PAYMENT_API from '../../api/payment'
import { PAYMENT, BILLDESK_MERCHANT_ID, BILLDESK_SECURITY_ID } from '../../service/config'
import { formatAmount, generateUniqueId, getCookie } from "../../service/helper"
import Loader from "../addon/Loader"

const MyAppointments = () => {

    const [appointmentData, setAppointmentData] = useState(null)
    const [defaultLimit] = useState(3)
    const [upcomingData, setUpcomingData] = useState([])
    const [pastData, setPastData] = useState([])
    const [loading, setLoading] = useState(false)
    const [breadcrumbs ] = useState([
        { label: "Home", link: "/",},
        { label: "Dashboard", link: "/user/dashboard",},
        { label: "My Appointments", },
    ]) 
    const metaTags = []

    const getAppointmentByHHNumber = (id) => {
        setLoading(true)
        DOCTOR_API.getAllAppoinmentByHHNumber(id)
        .then(res => {
            if(res) {
                setAppointmentData(res)
                loadMoreUpcomingData()
                loadMorePastData()
            }
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            toast.error("Something went wrong")
            setLoading(false)
        })
    }

    const loadMoreUpcomingData = () => {
        if(appointmentData?.upcomingAppointments?.length) {
            const counter = upcomingData.length + defaultLimit
            const arr = [...appointmentData.upcomingAppointments.slice(upcomingData.length, counter)]
            setUpcomingData([...upcomingData, ...arr])
        }   
    }

    const loadMorePastData = () => {
        if(appointmentData?.pastAppointments?.length) {
            const counter = pastData.length + defaultLimit
            const arr = [...appointmentData?.pastAppointments.slice(pastData.length, counter)]
            setPastData([...pastData, ...arr])
        }   
    }

    const navigateToPayment = async (selected) => {
        let json = {
            RegType: "HH",
            ApptNo: selected?.appt_no,
            TotalPayment: selected?.total
        }
        let obj = {}
        if(selected?.pat_pin_no) {
            try {
                let response = await PAYMENT_API.convertJsonDataToId(json)
                if(response) {
                    let uniqueId = response?.ref_no
                    let str = `${BILLDESK_MERCHANT_ID}|${uniqueId}|NA|${selected?.total+'.00'}|NA|NA|NA|INR|NA|R|hindujahsp|NA|NA|F|${selected?.pat_email}|${selected?.pat_mobileno}|${response}|NA|NA|NA|NA|https://admin.hindujahospital.com/api/billdesk/callBackUrl`
                    obj = {
                        msg: str,
                        callback: PAYMENT.CALLBACK
                    }
                    navigate(`/payment`,{ state: obj })
                }
            } 
            catch(err) {
                toast.error("Something went wrong")
            }
        }
    }

    useEffect(() => {
        if(getCookie('hh')) {
            getAppointmentByHHNumber(getCookie('hh'))
        }
    },[getCookie])

    useEffect(() => {
        if(appointmentData?.pastAppointments && appointmentData?.upcomingAppointments) {
            loadMoreUpcomingData()
            loadMorePastData()
        }
    },[appointmentData])
    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: ["/assets/css/blog.css", "/assets/css/accessibility.css"],
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <section className="section-bg section-py">
                    <div className="container">
                        <div className="arrowhead">
                            <Link to="/user/dashboard" className="back_arrow">
                            <img src="/assets/images/icons/left-arrow-event.svg" alt="" />			
                            </Link>						
                            {/* <a href="/my-account-edit" className="back_arrow"><img src="/assets/images/icons/left-arrow-event.svg" alt="" /></a> */}
                            <h2 className="section-heading">My Appointments</h2>
                        </div>
                    </div>
                    <div className="event_tab_wrap">
                        <div className="container">
                            <ul className="nav nav-tabs gallery-tabs" role="tablist">
                                <li role="presentation" className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#upcomingAppointments" role="tab" aria-controls="upcomingAppointments" aria-selected="true">Upcoming Appointments</a> </li>
                                <li role="presentation" className="nav-item"> <a className="nav-link" data-toggle="tab" href="#pastAppointments" role="tab" aria-controls="pastAppointments" aria-selected="false">Past Appointments</a> </li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane active" id="upcomingAppointments" role="tabpanel" aria-labelledby="upcomingAppointments">
                                <div className="container">
                                    <div className="row">
                                        {upcomingData?.length 
                                            ? upcomingData.map((el,i) => {
                                                let total = 0
                                                let chargeStr = ''
                                                if(el?.service?.length) {
                                                    el?.service.forEach((elem,j) => {
                                                        total = total+Number(elem?.serviceCharge)
                                                        chargeStr = `${chargeStr}${elem?.serviceDescription} ₹${elem?.serviceCharge}${el?.service?.length !== j+1 ? ' + ':''}`
                                                    })
                                                }
                                                upcomingData[i]['total'] = total
                                                upcomingData[i]['chargeStr'] = chargeStr
                                                return (
                                                    <div className="col-lg-4 col-md-6 t-data-col" key={i}>
                                                        <div class="card table_data_card">
                                                            <div class="data_row">
                                                                <span>Appointment No.</span>
                                                                <p>{el?.appt_no}</p>
                                                            </div>
                                                            <div class="data_row">
                                                                <span>Date &amp; time</span>
                                                                <p>{el?.appt_date} | {el?.appt_time}</p>
                                                            </div>
                                                            <div class="data_row">
                                                                <span>Consultant</span>
                                                                <p>{el?.appt_doctor_name}</p>
                                                            </div>
                                                            <div class="data_row">
                                                                <span>Amount</span>
                                                                <p>Rs. {formatAmount(el?.total)} ({el?.chargeStr})</p>
                                                            </div>
                                                            <div class="data_row">
                                                                <span>Service</span>
                                                                <p>{el?.service[0]?.serviceDescription}</p>
                                                            </div>
                                                            <div class="data_row btn_row">
                                                                {el?.appt_status === 'T' 
                                                                ? <button class="btn btn-success" onClick={() => navigateToPayment(el)}>Pay</button> 
                                                                : <button disabled class="btn btn-info">PAID</button>}
                                                            </div>
                                                        </div>
                                                    </div> 
                                                )
                                            })
                                            : null}
                                    </div>
                                    {
                                        (upcomingData?.length != appointmentData?.upcomingAppointments?.length && !loading) &&
                                        <div className="col-lg-12 text-center">
                                            <div className="card_link load_more">
                                                <LoadMoreButton
                                                    label="Load More"
                                                    callbackFunction={loadMoreUpcomingData}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className="col-lg-12">
                                            <Loader loadingMessage="Loading..." />
                                        </div>
                                    }
                                    {upcomingData?.length === 0 && !loading && <p>No data found.</p>}
                                </div>
                            </div>
                            <div className="tab-pane" id="pastAppointments" role="tabpanel" aria-labelledby="pastAppointments">
                                <div className="container">
                                    <div className="row">
                                        {pastData?.length 
                                            ? pastData.map((el,i) => {
                                                let total = 0
                                                let chargeStr = ''
                                                if(el?.service?.length) {
                                                    el?.service.forEach((elem,j) => {
                                                        total = total+Number(elem?.serviceCharge)
                                                        chargeStr = `${chargeStr}${elem?.serviceDescription} ₹${elem?.serviceCharge}${el?.service?.length !== j+1 ? ' + ':''}`
                                                    })
                                                }
                                                pastData[i]['total'] = total
                                                pastData[i]['chargeStr'] = chargeStr
                                                return (
                                                    <div className="col-lg-4 col-md-6 t-data-col" key={i}>
                                                        <div class="card table_data_card">
                                                            <div class="data_row">
                                                                <span>Appointment No.</span>
                                                                <p>{el?.appt_no}</p>
                                                            </div>
                                                            <div class="data_row">
                                                                <span>Date &amp; time</span>
                                                                <p>{el?.appt_date} | {el?.appt_time}</p>
                                                            </div>
                                                            <div class="data_row">
                                                                <span>Consultant</span>
                                                                <p>{el?.appt_doctor_name}</p>
                                                            </div>
                                                            <div class="data_row">
                                                                <span>Amount</span>
                                                                <p>Rs. {formatAmount(el?.total)} ({el?.chargeStr})</p>
                                                            </div>
                                                            <div class="data_row">
                                                                <span>Service</span>
                                                                <p>{el?.service[0]?.serviceDescription}</p>
                                                            </div>
                                                            <div class="data_row btn_row">
                                                                {el?.appt_status === 'T' 
                                                                ? <button class="btn btn-success" onClick={() => navigateToPayment(el)}>Pay</button> 
                                                                : <button disabled class="btn btn-info">PAID</button>}
                                                            </div>
                                                        </div>
                                                    </div> 
                                                )
                                            })
                                            : null}
                                    </div>
                                    {
                                        (pastData?.length != appointmentData?.pastAppointments?.length && !loading) &&
                                        <div className="col-lg-12 text-center">
                                            <div className="card_link load_more">
                                                <LoadMoreButton
                                                    label="Load More"
                                                    callbackFunction={loadMorePastData}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        loading &&
                                        <div className="col-lg-12">
                                            <Loader loadingMessage="Loading..." />
                                        </div>
                                    }
                                    {pastData?.length === 0 && !loading && <p>No data found.</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}
export default MyAppointments