import React, { useEffect, useState, useRef } from "react"
import toast from 'react-hot-toast'
import SimpleReactValidator from "simple-react-validator"
import { Link } from "gatsby"
import API from "../../api/user"
import Layout from "../layout/layout"
import Meta from "../addon/meta"
import Breadcrumbs from "../addon/breadcrumbs"

import useScript from "../../service/useScript"
import { checkIfValidJSON, getCookie, getNameInitials } from "../../service/helper"
import Loader from "../addon/Loader"
import { navigate } from 'gatsby-link'

const MyAccount = () => {
    const [breadcrumbs ] = useState([
        { label: "Home", link: "/",},
        { label: "Dashboard", link: "/user/dashboard",},
        { label: "My Account", },
    ])
    let [userInfo, setUserInfo] = useState(null)
    const [personalLoading, setPersonalLoading] = useState(true)
    const [personalThankyou, setPersonalThankyou] = useState(false)
    const [, forceUpdateForRegister] = useState();
    const [registerForm, setRegisterForm] = useState(
        {
            "PatPIN": "",
            "PatMobNo": "",
            "PatEmailId": "",
            "Tele_resident": "",
            "Tele_work": ""
        }
    )
    const [userHH, setUserHH] = useState(null)
    const [familyComment, setFamilyComment] = useState('')
    const [pastHistoryForm, setPastHistoryForm] = useState({})
    const [pastHistory, setPastHistory] = useState(null)
    const [lifestyleHistoryForm, setLifestyleHistoryForm] = useState({})
    const [lifestyleHistory, setLifestyleHistory] = useState(null)

    const [personalDetailValidator] = useState(useRef(new SimpleReactValidator({
        element: (message) => < p className = "field_error" > {message} </p>}
    )))

    const [validator] = useState(useRef(new SimpleReactValidator({
        element: (message) => < p className = "field_error" > {message} </p>}
    )))

    const metaTags = []
    const handleSubmit = () => {
        toast.success("Form Submitted successfully")
    }
    const genderOptions = [
        {
            code : 'M',
            description : 'Male'
        },
        {
            code : 'F',
            description : 'Female'
        },
        {
            code : 'O',
            description : 'Others'
        }
    ]
    useScript("/assets/js/custom_select.js")
    useScript("/assets/js/form-inputs-anim.js");
    useScript("/assets/js/login-register.js");

    const init = async (user) => {
        const countries = await getCountryData();
        const religions = await getAllReligionData();
        const relations = await getRelationsData();
        const maritalStatus = await getMaritalStatusData();

        API.getUserFromHH(JSON.parse(user)?.pin).then(response => {
            if(response?.Data){
                let d  = response?.Data instanceof Array && response?.Data.length > 0 ? response?.Data[0] : null;
                if(!d){
                    d  = Object.keys(response?.Data).length > 0 ? response?.Data : null;
                }
                if(countries){
                    const country = countries.find(c => c?.country_code === d?.patCountryCD)
                    if(country){
                        d['Country'] = country?.country_name;
                    }
                }
                if(religions){
                    const op = religions.find(c => c?.code === d?.patRegnalCode)
                    if(op){
                        d['patRegnalCode'] = op?.description;
                    }
                }
                if(relations){
                    const op = relations.find(c => c?.code === d?.patKinRelation)
                    if(op){
                        d['patKinRelation'] = op?.description;
                    }
                }
                if(maritalStatus){
                    const op = maritalStatus.find(c => c?.code === d?.patMaritalStatus)
                    if(op){
                        d['patMaritalStatus'] = op?.description;
                    }
                }
                const g = genderOptions.find(c => c?.code === d?.gender)
                if(g){
                    d['gender'] = g?.description;
                }

                setUserInfo(d)
                setRegisterForm({
                    "PatPIN": d?.pinNo,
                    "PatMobNo": d?.mobileno,
                    "PatEmailId": d?.email,
                    "Tele_resident": d?.patTelRes,
                    "Tele_work": d?.patTelWork
                })
            }
            
            setPersonalLoading(false)

        }).catch(err => {
            console.log(err);
            setPersonalLoading(false)
            navigate(`/user/dashboard`)
            toast.error("User not found")
        })
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRegisterForm({
            ...registerForm,
            [name]: value,
        });
    };
    const handlePersonalSubmit = () => {
        const personalResult = personalDetailValidator.current.allValid()
        if (personalResult === false) {
            personalDetailValidator.current.showMessages()
            forceUpdateForRegister(1)
        }else{
            API.updatePatient(registerForm)
            .then(response => {
                setPersonalThankyou(true);
                window.scroll({ top: 0, behavior: "smooth" })
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })
        }
    }
    const getCountryData = async () => {
        const res = await API.getAllCountry()
        .catch(err => {
            console.log(err)
        })
        if(res?.Data) {
            return res?.Data;
        }
    }
    const getAllReligionData = async () => {
        const res = await API.getReligions()
        .catch(err => {
            console.log(err)
        })
        if(res?.Data) {
            return res?.Data;
        }
    }
    const getRelationsData = async () => {
        const res = await API.getRelations()
        .catch(err => {
            console.log(err)
        })
        if(res?.Data) {
            return res?.Data;
        }
    }
    const getMaritalStatusData = async () => {
        const res = await API.getMaritalStatus()
        .catch(err => {
            console.log(err)
        })
        if(res?.Data) {
            return res?.Data;
        }
    }
    const getFamilyHistoryData = () => {
        API.getUserFamilyHistory(userHH)
        .then(res => {
            if(res?.length) {
                setFamilyComment(res?.[0]?.QUEST_COMMENT_VALUE)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    const addFamilyHistoryData = () => {
        let payload = {
            PatPIN : userHH,
            QUEST_ID_12 : 12,
            QUEST_VALUE_12 : '',
            QUEST_COMMENT_12 : familyComment
        }
        API.postUserFamilyHistory(payload)
        .then(res => {
            toast.success("Famlily Medical History Submitted Successfully")
            getFamilyHistoryData()
        })
        .catch(err => {
            let respError = err?.response?.data || 'Something went wrong'
            toast.error(respError)
        })
    }
    const handleFamilyHistory = () => {
        const result = validator.current.allValid()
        if (result === false) {
            validator.current.showMessages()
            forceUpdateForRegister(2)
        } else {
            addFamilyHistoryData()
        }
    }

    const handlePastRadioBtn = (e, i) => {
        const { value, name, id, checked } = e?.target
        setPastHistoryForm({
            ...pastHistoryForm,
            [name]: value
        })
    }
    const handlePastComments = (e) => {
        const { value, name } = e?.target
        setPastHistoryForm({
            ...pastHistoryForm,
            [name]: value
        })
    }
    const getPastHistoryData = () => {
        API.getUserPastHistory(userHH)
        .then(res => {
            if(res?.length) {
                setPastHistory(res)
                let obj = {
                    'PatPIN': userHH
                }
                res.forEach((el,i) => {
                    let current = i+1
                    obj['QUEST_ID_'+current] = current;
                    obj['QUEST_VALUE_'+current] = el?.QUEST_VALUE;
                    obj['QUEST_COMMENT_'+current] = el?.QUEST_COMMENT_VALUE
                })
                setPastHistoryForm({
                    ...pastHistoryForm,
                    ...obj
                })
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    const addPastHistoryData = () => {
        API.postUserPastHistory(pastHistoryForm)
        .then(res => {
            toast.success("Past History Submitted Successfully")
            getPastHistoryData()
        })
        .catch(err => {
            let respError = err?.response?.data || 'Something went wrong'
            toast.error(respError)
        })
    }
    const handlePastHistory = () => {
        addPastHistoryData()
    }

    const handleLifestyleRadioBtn = (e, i) => {
        const { value, name, id, checked } = e?.target
        setLifestyleHistoryForm({
            ...lifestyleHistoryForm,
            [name]: value
        })
    }
    const handleLifestyleComments = (e) => {
        const { value, name } = e?.target
        setLifestyleHistoryForm({
            ...lifestyleHistoryForm,
            [name]: value
        })
    }
    const getLifestyleHistoryData = () => {
        API.getUserLifestyleHistory(userHH)
        .then(res => {
            if(res?.length) {
                setLifestyleHistory(res)
                let obj = {
                    'PatPIN': userHH
                }
                res.forEach((el,i) => {
                    let current = i+8
                    obj['QUEST_ID_'+current] = current;
                    obj['QUEST_VALUE_'+current] = el?.QUEST_VALUE;
                    obj['QUEST_COMMENT_'+current] = el?.QUEST_COMMENT_VALUE
                })
                setLifestyleHistoryForm({
                    ...lifestyleHistoryForm,
                    ...obj
                })
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
    const addLifestyleHistoryData = () => {
        API.postUserLifestyleHistory(lifestyleHistoryForm)
        .then(res => {
            toast.success("Lifestyle History Submitted Successfully")
            getLifestyleHistoryData()
        })
        .catch(err => {
            let respError = err?.response?.data || 'Something went wrong'
            toast.error(respError)
        })
    }
    const handleLifestyleHistory = () => {
        addLifestyleHistoryData()
    }

    useEffect(() => {
        const user = getCookie("user");
        const hhNo = getCookie("hh");
        if(hhNo) {
            setUserHH(hhNo)
        }
        if (user && checkIfValidJSON(user)) {
            init(user);
        }
    }, [])

    useEffect(() => {
        if(userHH) {
            getFamilyHistoryData()
            getPastHistoryData()
            getLifestyleHistoryData()
        }
    },[userHH])

return (
<>
<Layout>
    <Meta
    files={{
    js: [],
    css: ["/assets/css/login.css", "/assets/css/accessibility.css"],
    }}
    tags={metaTags}
    />
    <main className="innerpage">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <section className="section_bg login_section pt-first-section">
            <div className="container">
                <div className="arrowhead">
                    <Link to="/user/dashboard" className="back_arrow">
                    <   img src="/assets/images/icons/left-arrow-event.svg" alt="" />
                    </Link>
                    <h2 className="section-heading">My Account</h2>
                </div>
                <div className="row no-gutters">
                    <div className="col-lg-12 col-md-12">
                        <div className="white_rounded_box">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#tab-personal" role="tab">Personal</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tab-medical" role="tab">Past History</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tab-lifestyle" role="tab">Lifestyle</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tab-fmh" role="tab">Family Medical History</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active" id="tab-personal" role="tabpanel">
                                    {personalLoading ? 
                                            <Loader loadingMessage="Loading..."/>
                                        :
                                        <div>
                                            {!personalThankyou &&
                                                <div className="form_wrap" id="register_form_wrap">
                                                    <div className="form_block pl-0">
                                                        {/* <h3>Personal Details</h3> */}
                                                        <div className="detail_wrap form_block">
                                                            <span class="vertical_line"></span>
                                                            <h3>Personal Details</h3>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>First Name</label>
                                                                                <input className="form-control" type="text" value={userInfo?.firstname} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Middle Name</label>
                                                                                <input className="form-control" type="text" value={userInfo?.midname}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Last Name</label>
                                                                                <input className="form-control" type="text" value={userInfo?.lastname}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Religion</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patRegnalCode} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className={`form-group -animated ${registerForm?.PatEmailId ? '-active' : ''}`}>
                                                                                <label>Email Address <span className="mandatory">*</span></label>
                                                                                <input
                                                                                    onChange={handleInputChange}
                                                                                    name="PatEmailId"
                                                                                    value={registerForm?.PatEmailId}
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                {personalDetailValidator.current.message('PatEmailId', registerForm?.PatEmailId, 'required|email')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Age</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patage} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Occupation</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patProfession} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Gender</label>
                                                                                <input className="form-control" type="text" value={userInfo?.gender}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Marital Status</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patMaritalStatus} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Aadhaar No.</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patAdharNo} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Pan No.</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patPanNo}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>HH No.</label>
                                                                                <input className="form-control" type="text" value={userInfo?.pinNo} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="detail_wrap form_block">
                                                            <span class="vertical_line"></span>
                                                            <h3>Contact Details</h3>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Address</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patAdd1} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>City/Town</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patAdd2}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>State</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patState} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Country</label>
                                                                                <input className="form-control" type="text" value={userInfo?.Country}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>PinCode</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patPOBOX}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Nationality</label>
                                                                                <input className="form-control" type="text" value={userInfo?.Country}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className={`form-group -animated ${registerForm?.PatMobNo ? '-active' : ''}`}>
                                                                                <label>Mobile No. <span className="mandatory">*</span></label>
                                                                                <input
                                                                                    onChange={handleInputChange}
                                                                                    name="PatMobNo"
                                                                                    value={registerForm?.PatMobNo}
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                {personalDetailValidator.current.message('PatMobNo', registerForm?.PatMobNo, 'required|numeric|size:10')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className={`form-group -animated ${registerForm?.Tele_resident ? '-active' : ''}`}>
                                                                                <label>Tel. Number (Resi.)</label>
                                                                                <input
                                                                                    onChange={handleInputChange}
                                                                                    name="Tele_resident"
                                                                                    value={registerForm?.Tele_resident}
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                {personalDetailValidator.current.message('Tele_resident', registerForm?.Tele_resident, 'numeric')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className={`form-group -animated ${registerForm?.Tele_work ? '-active' : ''}`}>
                                                                                <label>Tel. Number (Office)</label>
                                                                                <input
                                                                                    onChange={handleInputChange}
                                                                                    name="Tele_work"
                                                                                    value={registerForm?.Tele_work}
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                {personalDetailValidator.current.message('Tele_work', registerForm?.Tele_work, 'numeric')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="detail_wrap form_block">
                                                            <span class="vertical_line"></span>
                                                            <h3>Responsible Person / Kin Details</h3>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-3">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Relation</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patKinRelation} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>First Name</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patKinFirstName}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Middle Name</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patKinMidName}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Last Name</label>
                                                                                <input className="form-control" type="text" value={userInfo?.lastname}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className="form-group -animated -active select-disabled">
                                                                                <label>Contact No.</label>
                                                                                <input className="form-control" type="text" value={userInfo?.patKinResTel} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form_block pl-0">
                                                        <button className="btn btn-primary" onClick={handlePersonalSubmit}>Edit & Save</button>
                                                    </div>
                                                </div>
                                            }
                                            <div className="tankyou_div" style={{ display: personalThankyou === true ? "block" : "none" }}>
                                                <div className="check_mark">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                                        <g id="Group_2657" data-name="Group 2657" transform="translate(0)">
                                                            <path id="Path_3040" data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470"/>
                                                            <g id="Group_2463" data-name="Group 2463" transform="translate(14.719 18.535)">
                                                                <line id="Line_241" data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4"/>
                                                                <line id="Line_242" data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className="thankyou_content">
                                                    <p>Thank you <span>{userInfo?.fullname}</span> for sharing your contact details.</p>
                                                    <p>Your HH number is {userInfo?.pinNo}.</p>
                                                </div>
                                                <div className="btn_wrap">
                                                    <Link to="/user/dashboard" className="btn btn-primary">Continue</Link>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            <div className="tab-pane" id="tab-medical" role="tabpanel">
                                <div className="form_wrap">
                                    <h2>Past History</h2>
                                        {/* <div className="row">
                                            {pastHistory?.length 
                                            ? pastHistory.map((el, i) => {
                                                return (
                                                    <div className="col-md-4" key={i}>
                                                        <div className="form-group radio-group mb-0">
                                                            <label className="radio-main-label">{el?.QUEST_DESC}</label>
                                                            <div className="row">
                                                                <div className="col-md-4 col-6">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" checked={pastHistoryForm[`QUEST_VALUE_${i+1}`] === el?.QUEST_OPT1} onChange={(e) => handlePastRadioBtn(e, el?.QUEST_DESC)} value={el?.QUEST_OPT1} name={`QUEST_VALUE_${i+1}`} id={el?.QUEST_OPT1} />
                                                                        <label htmlFor={el?.QUEST_OPT1}>{el?.QUEST_OPT1}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-6">
                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio" checked={pastHistoryForm[`QUEST_VALUE_${i+1}`] === el?.QUEST_OPT2} onChange={(e) => handlePastRadioBtn(e, i)} value={el?.QUEST_OPT2} name={`QUEST_VALUE_${i+1}`} id={el?.QUEST_OPT2} />
                                                                        <label htmlFor={el?.QUEST_OPT2}>{el?.QUEST_OPT2}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group textarea -animated">
                                                            <label>Comments </label>
                                                            <textarea className="form-control" name={`QUEST_COMMENT_${i+1}`} onChange={handlePastComments} value={pastHistoryForm?.[`QUEST_COMMENT_${i+1}`]}></textarea>
                                                        </div>
                                                    </div>
                                                )
                                            }) 
                                            : null}
                                        </div> */}
                                       
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">Allergy (any particular drug)    </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_1?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handlePastRadioBtn(e, 1)} value="YES" name="QUEST_VALUE_1" id="Allergyyes" />
                                                            <label for="Allergyyes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_1?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handlePastRadioBtn(e, 1)} value="NO" name="QUEST_VALUE_1" id="Allergyno" />
                                                            <label for="Allergyno">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_1`} onChange={handlePastComments} value={pastHistoryForm[`QUEST_COMMENT_1`]}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">Daibetes     </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_2?.toUpperCase() === 'YES'} onChange={(e) => handlePastRadioBtn(e, 2)} value="YES" name="QUEST_VALUE_2" id="Daibetesyes" />
                                                            <label for="Daibetesyes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_2?.toUpperCase() === 'NO'} onChange={(e) => handlePastRadioBtn(e, 2)} value="NO" name="QUEST_VALUE_2" id="Daibetesno" />
                                                            <label for="Daibetesno">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_2`} onChange={handlePastComments} value={pastHistoryForm[`QUEST_COMMENT_2`]}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">High Blood Pressure      </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_3?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handlePastRadioBtn(e, 3)} value="YES" name="QUEST_VALUE_3" id="HighBPyes" />
                                                            <label for="HighBPyes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_3?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handlePastRadioBtn(e, 3)} value="NO" name="QUEST_VALUE_3" id="HighBPno" />
                                                            <label for="HighBPno">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_3`} onChange={handlePastComments} value={pastHistoryForm[`QUEST_COMMENT_3`]}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">Coronary Heart Disease      </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_4?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handlePastRadioBtn(e, 4)} value="YES" name="QUEST_VALUE_4" id="CoronaryHDyes" />
                                                            <label for="CoronaryHDyes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_4?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handlePastRadioBtn(e, 4)} value="NO" name="QUEST_VALUE_4" id="CoronaryHDno" />
                                                            <label for="CoronaryHDno">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_4`} onChange={handlePastComments} value={pastHistoryForm[`QUEST_COMMENT_4`]}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">TB      </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_5?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handlePastRadioBtn(e, 5)} value="YES" name="QUEST_VALUE_5" id="TByes" />
                                                            <label for="TByes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_5?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handlePastRadioBtn(e, 5)} value="NO" name="QUEST_VALUE_5" id="TBno" />
                                                            <label for="TBno">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_5`} onChange={handlePastComments} value={pastHistoryForm[`QUEST_COMMENT_5`]}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">Asthma      </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_6?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handlePastRadioBtn(e, 6)} value="YES" name="QUEST_VALUE_6" id="Asthmayes" />
                                                            <label for="Asthmayes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_6?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handlePastRadioBtn(e, 6)} value="NO" name="QUEST_VALUE_6" id="Asthmano" />
                                                            <label for="Asthmano">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_6`} onChange={handlePastComments} value={pastHistoryForm[`QUEST_COMMENT_6`]}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">Thyroid issues       </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_7?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handlePastRadioBtn(e, 7)} value="YES" name="QUEST_VALUE_7" id="Thyroidyes" />
                                                            <label for="Thyroidyes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={pastHistoryForm?.QUEST_VALUE_7?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handlePastRadioBtn(e, 7)} value="NO" name="QUEST_VALUE_7" id="Thyroidno" />
                                                            <label for="Thyroidno">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_7`} onChange={handlePastComments} value={pastHistoryForm[`QUEST_COMMENT_7`]}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <button className="btn btn-primary" onClick={handlePastHistory}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="tab-lifestyle" role="tabpanel">
                                <div className="form_wrap">
                                    <h2>Lifestyle</h2>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">Do you smoke / Chew tobacco/ snuff   </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={lifestyleHistoryForm?.QUEST_VALUE_8?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handleLifestyleRadioBtn(e, 8)} value="YES" name="QUEST_VALUE_8" id="smokeyes" />
                                                            <label for="smokeyes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={lifestyleHistoryForm?.QUEST_VALUE_8?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handleLifestyleRadioBtn(e, 8)} value="NO" name="QUEST_VALUE_8" id="smokeno" />
                                                            <label for="smokeno">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_8`} onChange={handleLifestyleComments} value={lifestyleHistoryForm[`QUEST_COMMENT_8`]}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">Do you consume alochol  </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={lifestyleHistoryForm?.QUEST_VALUE_9?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handleLifestyleRadioBtn(e, 9)} value="YES" name="QUEST_VALUE_9" id="alocholyes" />
                                                            <label for="alocholyes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={lifestyleHistoryForm?.QUEST_VALUE_9?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handleLifestyleRadioBtn(e, 9)} value="NO" name="QUEST_VALUE_9" id="alocholno" />
                                                            <label for="alocholno">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_9`} onChange={handleLifestyleComments} value={lifestyleHistoryForm[`QUEST_COMMENT_9`]}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">Do you Exercise regularly    </label>
                                                <div className="row">
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={lifestyleHistoryForm?.QUEST_VALUE_10?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handleLifestyleRadioBtn(e, 10)} value="YES" name="QUEST_VALUE_10" id="Exerciseyes" />
                                                            <label for="Exerciseyes"> Yes</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={lifestyleHistoryForm?.QUEST_VALUE_10?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handleLifestyleRadioBtn(e, 10)} value="NO" name="QUEST_VALUE_10" id="Exerciseno" />
                                                            <label for="Exerciseno">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" name={`QUEST_COMMENT_10`} onChange={handleLifestyleComments} value={lifestyleHistoryForm[`QUEST_COMMENT_10`]}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group radio-group mb-0">
                                                <label className="radio-main-label">Diet     </label>
                                                <div className="row">
                                                    <div className="col-md-6 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={lifestyleHistoryForm?.QUEST_VALUE_11?.toUpperCase() === 'YES' ? true : false} onChange={(e) => handleLifestyleRadioBtn(e, 11)} value="YES" name="QUEST_VALUE_11" id="DietVeg" />
                                                            <label for="DietVeg"> Vegetarian</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-6">
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" checked={lifestyleHistoryForm?.QUEST_VALUE_11?.toUpperCase() === 'NO' ? true : false} onChange={(e) => handleLifestyleRadioBtn(e, 11)} value="NO" name="QUEST_VALUE_11" id="DietNonVeg" />
                                                            <label for="DietNonVeg">Non - veg</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <button className="btn btn-primary" onClick={handleLifestyleHistory}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="tab-fmh" role="tabpanel">
                                <div className="form_wrap">
                                    <h2>Famlily Medical History</h2>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group textarea -animated">
                                                <label>Comments </label>
                                                <textarea className="form-control" value={familyComment} onChange={(e) => setFamilyComment(e?.target?.value)}></textarea>
                                                {validator.current.message('familyComment', familyComment, '')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <button className="btn btn-primary" onClick={handleFamilyHistory}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    </main>
</Layout>
</>
)
}
export default MyAccount