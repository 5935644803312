import React from 'react'

const LoadMoreButton = (props) => {

    const {
        label,
        callbackFunction,
        loading
    } = props

    const onBtnClick = () => {
        if (callbackFunction) {
            callbackFunction()
        }
    }

    return (
        <>
            {
                loading === true ?
                <p>Loading....</p>
                :
                <a
                    href="javascript:;"
                    className="btn btn-primary"
                    onClick={onBtnClick}
                >{label ? label : 'Load More'}</a>
            }
        </>
    )
}

export default LoadMoreButton
