import React, { useState,useEffect } from 'react'
import Breadcrumbs from '../addon/breadcrumbs'
import Meta from '../addon/meta'
import Layout from '../layout/layout'
import API, { initHeaders } from '../../api/events'
import MyEventCard from '../addon/myEventsCard'
import Loader from '../addon/Loader'
import useScript from '../../service/useScript';

const MyEvents = () => {
useScript("/assets/js/custom_select.js");
useScript("/assets/js/myEvents.js");
const metaTags = [];
const [breadcrumbs, setBreadcrumbs] = useState([
{
label: 'Home',
link: '/'
},
{
label: 'Events',
}
])
const [loading, setLoading] = useState(false)
const [pastEvents, setPastEvents] = useState([])
const [futureEvents, setFutureEvents] = useState([])
useEffect(() => {
setLoading(true)
initHeaders()
API.registeredEvents().then(res => {
setPastEvents(res.past);
setFutureEvents(res.future);
setLoading(false)
}).catch(err =>console.log(err)) 
}, [])
return (
<>
<Layout>
    <Meta
    title="Events"
    files={{
    js: [],
    css: ["/assets/css/blog.css","/assets/css/accessibility.css"]
    }}
    tags={metaTags}
    />
    <main className="innerpage">
        <Breadcrumbs
            breadcrumbs={breadcrumbs}
            />
        <section className="section-bg section-py">
            <div className="container">
                <div className="arrowhead">
                    <a href="events-listing.html" className="back_arrow"><img src="/assets/images/icons/left-arrow-event.svg" alt="" /></a>
                    <h2 className="section-heading">John Doe</h2>
                </div>
            </div>
            <div className="event_tab_wrap">
                <div className="container">
                    <ul className="nav nav-tabs gallery-tabs" role="tablist">
                        <li role="presentation" className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#upcomingEvents" role="tab" aria-controls="upcomingEvents" aria-selected="true">Upcoming Events</a>
                        </li>
                        <li role="presentation" className="nav-item">
                            <a className="nav-link " data-toggle="tab" href="#pastEvents" role="tab" aria-controls="pastEvents" aria-selected="false">Past Events</a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content">
                    <div className="tab-pane active" id="upcomingEvents" role="tabpanel" aria-labelledby="upcomingEvents">
                        <div className="container">
                            <div className="row">
                                {
                                futureEvents && futureEvents.length > 0
                                && 
                                futureEvents.map((item,index) => {
                                return (
                                <MyEventCard
                                    key={index}
                                    data={item}
                                    future={true}
                                    />
                                )
                                })
                                }
                                {
                                loading === true  && 
                                <div className="text-center">
                                    <Loader loadingMesssage="Loading..." />
                                </div>
                                }
                                {
                                loading=== false && futureEvents.length === 0 &&  
                                <div className="text-center">
                                    <p>Currentlty there is no data</p>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="pastEvents" role="tabpanel" aria-labelledby="pastEvents">
                        <div className="container">
                            <div className="row">
                                {
                                pastEvents && pastEvents.length > 0 &&
                                pastEvents.map((item, index) => {
                                return (
                                <MyEventCard
                                    key={index}
                                    data={item}
                                    future={false}
                                    />
                                )
                                })                  
                                }
                                { 
                                loading === true &&
                                <div className="text-center">
                                    <Loader loadingMesssage="Loading..." />
                                </div>
                                }
                                {
                                loading === false && pastEvents.length === 0 &&  
                                <div className="text-center">
                                    <p>Currentlty there is no data</p>
                                </div>
                                }              
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-bg section-py">
                    <div className="pushNotify">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <div className="text_content">
                                        <h2>Push Notification</h2>
                                        <p>Get our free e-newsletter to stay up to date on the latest health information from experts at Hinduja Hospital.</p>
                                    </div>
                                    
                                    <label className="switch">
                                    <input type="checkbox" checked />
                                    <span className="switch_slider switch_round"></span>
                                    </label>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</Layout>
</>
)
}
export default MyEvents