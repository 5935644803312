import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import toast from "react-hot-toast"
import { navigate } from 'gatsby-link'

import Layout from "../layout/layout"
import Meta from "../addon/meta"
import Breadcrumbs from "../addon/breadcrumbs"
import Loader from "../addon/Loader"

import DOCTOR_API from '../../api/doctors'
import { getCookie } from "../../service/helper"

const MyBills = () => {

    const [hhNumber, setHHNumber] = useState(null)
    const [loading, setLoading] = useState(false)
    const [apiData, setApiData] = useState(null)
    const [showCard, setShowCard] = useState(false)
    const [breadcrumbs ] = useState([
        { label: "Home", link: "/",},
        { label: "Dashboard", link: "/user/dashboard",},
        { label:"My Bills", },
    ]) 
    const metaTags = []

    const getInterimBill = () => {
        setLoading(true)
        let payload = {
            hhNumber: hhNumber
        }
        DOCTOR_API.getInterimBill(payload)
        .then(res => {
            if(res?.success) {
                setApiData(res?.data)
                setShowCard(true)
            } else {
                toast.error(res?.message)
                setShowCard(false)
            }
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
            toast.error(err?.message ? err.message : 'Something went wrong' )
        })
    }

    const downloadBill = () => {
        let fileURL = window.URL.createObjectURL(new Blob([apiData?.data], {type: "application/pdf"}))
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "HH-interim-bill.pdf";
        link.click();
    }

    const viewBillOnline = () => {
        let fileURL = window.URL.createObjectURL(new Blob([apiData?.data], {type: "application/pdf"}))
        let tab = window.open();
        tab.location.href = fileURL;
    }

    useEffect(() => {
        if(hhNumber) getInterimBill()
    },[hhNumber])

    useEffect(() => {
        if(!getCookie('hh')) {
            navigate(`/user/login`)
        } else {
            setHHNumber(getCookie('hh'))
        }
    },[getCookie('hh')])

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: ["/assets/css/blog.css", "/assets/css/accessibility.css"],
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <section className="section-bg section-py">
                    <div className="container">
                        <div className="arrowhead">
                            <Link to="/user/dashboard" className="back_arrow">
                                <img src="/assets/images/icons/left-arrow-event.svg" alt="" />			
                            </Link>
                            <h2 className="section-heading">My Bills</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 t-data-col">
                                {loading && <Loader loadingMessage="Loading Data..." />}
                                {showCard && !loading &&
                                <div className="card table_data_card mb-0 d-flex align-items-center justify-content-center">
                                    <div>
                                        <p className="mb-4">Click on the below buttons to view the bills.</p>
                                        <div className="data_row btn_row">
                                            <button className="btn btn-primary" onClick={downloadBill}>Download</button>
                                            <button className="btn btn-success" onClick={viewBillOnline}>View Online</button>
                                        </div>
                                    </div>
                                </div>}
                                {!showCard && !loading && 
                                    <p>Currently there are no bills.</p>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default MyBills